export enum PERMISSIONS {
  QUERY_SEARCH_RETAIL_DATA_IMPORT = 'query/searchretaildataimport',
  MUTATION_IMPORT_CHAT_BOT_QUESTIONS = 'mutation/importchatbotquestions',
  QUERY_SEARCH_AI_SETTINGS = 'query/searchaisettings',
  QUERY_GET_LIVE_CHAT_AGENT_DATA = 'query/getlivechatagentdata',
  QUERY_GET_LIVE_CHAT_HISTORY = 'query/getlivechathistory',
  QUERY_GET_LIVE_CHAT_AGENT_TEAMS = 'query/getlivechatagentteams',
  QUERY_GET_LIVE_CHAT_AGENTS = 'query/getlivechatagents',
  QUERY_GET_LIVE_CHAT_CATEGORIES = 'query/getlivechatcategories',
  MUTATION_UPDATE_LIVE_CHAT_AGENT_TEAM_AGENTS = 'mutation/updatelivechatagentteamagents',
  MUTATION_UPDATE_LIVE_CHAT_AGENT_TEAM_CATEGORIES = 'mutation/updatelivechatagentteamcategories'
}
