import retailGlobal from '@retail/global';
import * as retailDotenv from '@retail/dotenv';
import { loadConfig } from '@retail/config';

if (__IS_STANDALONE__) {
  require('./antd.global.less');
}

const ENV = __IS_STANDALONE__
  ? process.env.ENV
  : retailGlobal.get<{ ENV?: string }>('__config')?.ENV;

loadConfig(retailDotenv.load(ENV));
